import { createRouter, createWebHistory } from "@ionic/vue-router";
const routes = [
  {
    path: "/:pathMatch(.*)*",
    name: "not-found",
    component: () => import("../components/NotFoundPage.vue"),
  },
  {
    path: "/",
    redirect: { name: "Login" },
  },
  {
    path: "/login",
    component: () => import("../views/LoginPage.vue"),
    name: "Login",
  },
  {
    path: "/signup",
    component: () => import("../views/SignupPage.vue"),
    name: "Signup",
  },

  {
    path: "/home",
    meta: { requiresAuth: true },
    name: "HeaderLayout",
    component: () => import("../views/MainLayoutPage.vue"),
    children: [
      {
        path: "",
        name: "Home",
        redirect: { name: "Wallets" },
      },
      {
        path: "wallets",
        name: "Wallets",
        component: () => import("../views/WalletListPage.vue"),
      },
      {
        path: "account",
        name: "Account",
        component: () => import("../views/AccountPage.vue"),
      },
      {
        path: "wallets/create",
        name: "CreateWallet",
        component: () => import("../views/CreateWalletPage.vue"),
      },
      {
        path: "collections",
        name: "Collections",
        component: () => import("../views/NftListPage.vue"),
      },
      {
        path: "collections/:id",
        name: "NFTDetails",
        component: () => import("../views/DetailPage.vue"),
      },
      {
        path: "collections/import",
        name: "ImportNFT",
        component: () => import("../views/ImportNFTPage.vue"),
      },
    ],
  },
  {
    path: "/wallets",
    redirect: { name: "Wallets" },
  },
  {
    path: "/wallets/:id",
    name: "TokenLayout",
    meta: { requiresAuth: true },
    props: (route) => ({
      id: parseInt(route.params.id, 10),
    }),
    component: () => import("../views/token/TokenTabsPage.vue"),
    children: [
      {
        path: "",
        name: "SelectNetwork",
        component: () => import("../views/token/TokenTabSelectNetworkPage.vue"),
      },
      {
        path: "scan",
        name: "ScanQrCode",
        component: () => import("../views/token/TokenTabScanQrPage.vue"),
      },
      {
        path: "send",
        name: "TokenSend",
        component: () => import("../views/token/TokenTabSendPage.vue"),
        props: true,
      },
      {
        path: "receive",
        name: "TokenReceive",
        props: true,
        component: () => import("../views/token/TokenTabReceivePage.vue"),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const loggedIn = localStorage.getItem("jwt-token");
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!loggedIn) {
      next("/");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
