<template class="app-background">
  <ion-app class="app-container">
    <ion-router-outlet />
  </ion-app>
</template>

<script setup>
import { IonApp, IonRouterOutlet } from "@ionic/vue";
console.log(process.env);
</script>
